<template>
  <v-row justify="center" class="ma-0 pa-0">
    <v-col cols="12" class="my-4">
      <h1 class="text-h4 primary--text text-center">
        Exports des utilisateurs
      </h1>
    </v-col>

    <v-col cols="12" sm="8" md="8">
      <v-card>
        <v-stepper alt-labels v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step step="1">Recherche</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">Sélection des utilisateurs</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">Résumé</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form lazy-validation ref="recherche-utilisateur">
                <v-autocomplete
                  @blur="onEtablissementsSelected"
                  v-model="etablissementsSelected"
                  :items="etablissements"
                  :loading="loading"
                  multiple
                  persistent-hint
                  label="Etablissements *"
                  class="pa-1"
                  required
                ></v-autocomplete>

                <v-select
                  v-model="classeSelected"
                  :items="classes"
                  label="Classes"
                  multiple
                  class="pa-1"
                  :rules="[() => !!classeSelected || 'Ce champ est requis']"
                  required
                ></v-select>

                <v-select
                  v-model="status"
                  :items="statuts"
                  label="Statut des utilisateurs"
                  class="pa-1"
                  clearable
                ></v-select>

                <v-select
                  v-model="role"
                  :items="roles"
                  label="Rôle des utilisateurs"
                  class="pa-1"
                  clearable
                ></v-select>

                <v-btn
                  name="Rechercher"
                  height="44px"
                  color="primary"
                  dark
                  class="AccessButton pl-3 pr-3"
                  tile
                  :loading="loading"
                  elevation="0"
                  fab
                  @click="validate"
                  width="180"
                >
                  Rechercher
                  <v-spacer />
                  <v-icon class="ml-5"> mdi-magnify</v-icon>
                </v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table
                v-model="selectedUsers"
                :options.sync="pageInfo"
                :headers="headers"
                :items="utilisateurs"
                :loading="loading"
                :server-items-length="pageInfo.totalItems"
                item-key="identifiant"
                @toggle-select-all="selectAll"
                @update:sort-by="changeSort"
                @update:sort-desc="changeSortDesc"
                @update:page="setPage"
                @update:items-per-page="setPageSize"
                show-select
                class="elevation-1"
              >
                <template v-slot:item.etablissements="{ item }">
                  {{ item.etablissements[0].id }}
                </template>
                <template v-slot:[`item.actif`]="{ item }">
                  <div class="d-flex">
                    <v-icon class="mr-2" :color="getColor(item.actif)">
                      {{ actifText(item.actif) }}
                    </v-icon>
                  </div>
                </template>
              </v-data-table>

              <v-btn
                name="Suivant"
                height="44px"
                color="primary"
                dark
                class="AccessButton mt-3 mr-3"
                tile
                elevation="0"
                :loading="loading"
                @click="currentStep--"
                fab
                width="180"
              >
                Retour
              </v-btn>

              <v-btn
                name="Suivant"
                height="44px"
                color="secondary"
                dark
                class="AccessButton mt-3 mr-3"
                tile
                v-if="selectedUsers.length > 0"
                elevation="0"
                fab
                @click="currentStep++"
                width="180"
              >
                Suivant
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <!-- Show list user selected and book with amount -->
              <v-row>
                <v-col cols="12" class="text-h5">
                  <h1 class="text-h4 primary--text text-center">
                    Résumé de l'export
                  </h1>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <h1 class="text-h5 primary--text text-center">
                    Utilisateurs sélectionnés
                  </h1>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <v-data-table
                    :headers="headers"
                    :items="selectedUsers"
                    :loading="loading"
                    item-key="id"
                    class="elevation-1"
                  >
                    <template v-slot:item.etablissements="{ item }">
                      {{ item.etablissements[0].id }}
                    </template>
                    <template v-slot:[`item.actif`]="{ item }">
                      <div class="d-flex">
                        <v-icon class="mr-2" :color="getColor(item.actif)">
                          {{ actifText(item.actif) }}
                        </v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row class="mb-4">
                <v-btn
                  name="Suivant"
                  height="44px"
                  color="primary"
                  dark
                  class="AccessButton mt-3 mr-3"
                  tile
                  elevation="0"
                  :loading="loading"
                  @click="currentStep--"
                  fab
                  width="180"
                >
                  Retour
                </v-btn>

                <v-btn
                  @click="exporter"
                  class="AccessButton mt-3 mr-3"
                  name="Suivant"
                  height="44px"
                  color="secondary"
                  dark
                  tile
                  elevation="0"
                  :loading="loading"
                  fab
                  width="180"
                >
                  Exporter en CSV
                </v-btn>

                <v-btn
                  name="Suivant"
                  height="44px"
                  color="secondary"
                  dark
                  class="AccessButton mt-3 mr-3"
                  tile
                  elevation="0"
                  :loading="loading"
                  @click="exporterQrCode"
                  fab
                  width="180"
                >
                  Exporter en PDF
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AdminService from "@/services/AdminService";
import Utils from "@/utils/utils";

export default {
  name: "ExportTab",
  data: () => ({
    currentStep: 1,
    etablissements: [],
    classes: [],

    status: null,
    statuts: ["Actif", "Inactif"],

    role: null,
    roles: [
      {
        text: "Élève",
        value: "ELEVE",
      },
      {
        text: "Professeur",
        value: "PROF",
      },
    ],

    loading: false,

    etablissement: null,
    classeSelected: [],
    etablissementsSelected: [],

    headers: [
      { text: "Identifiant", value: "identifiant" },
      { text: "Nom", value: "nom" },
      { text: "Prénom", value: "prenom" },
      { text: "Classes", value: "classes" },
      { text: "Rôle", value: "role" },
      { text: "Statut", value: "actif" },
      // Set sortable to true when migrating to Spring Boot 3
      { text: "Etablissement", value: "etablissements", sortable: false },
    ],
    pageInfo: {
      page: 1,
      pageCount: 0,
      totalItems: 0,
      itemsPerPageOptions: [5, 10, 25, 50, 100],
      itemsPerPage: 10,
      itemsPerPageLabel: "Lignes par page",
      sortDesc: [],
      sortBy: [],
    },

    json_fields: {
      identifiant: "utilisateur.identifiant",
      nom: "utilisateur.nom",
      prenom: "utilisateur.prenom",
      emailContact: "utilisateur.emailContact",
      ID_Parent: "utilisateur.codeAdherent",
      role: "utilisateur.role",
      actif: "utilisateur.actif",
      soutienBordas: "utilisateur.soutienBordas",
      classes: "utilisateur.classes",
      dateCreation: "utilisateur.dateCreation",
      dateActivation: "utilisateur.dateActivation",
      dateConnexion: "utilisateur.dateConnexion",
      solde: "utilisateur.solde",
      etablissementID: "utilisateur.etablissements.id",
      etablissementUAI: "utilisateur.etablissements.uai",
      etablissementNom: "utilisateur.etablissements.nom",
      etablissementCp: "utilisateur.etablissements.cp",
      etablissementVille: "utilisateur.etablissements.ville",
      eans: "eans",
    },

    utilisateurs: [],
    selectedUsers: [],
    selectedBooks: [],
    devis: null,

    allUsers: [],
  }),
  mounted() {
    this.etablissementArray();
  },
  methods: {
    validate() {
      if (this.currentStep === 1) {
        if (this.etablissementsSelected.length === 0) {
          return false;
        }
        if (this.classeSelected.length === 0) {
          this.classeSelected = this.classes;
        }
        this.$refs["recherche-utilisateur"].validate();
        if (this.$refs["recherche-utilisateur"].validate()) {
          this.currentStep++;
          this.getUtilisateurEtablissement(this.etablissementsSelected);
        }
      }
    },

    etablissementArray() {
      if (!this.etablissements.length) {
        AdminService.getEtablissementIds().then(({ data }) => {
          data.forEach((etablissement) => {
            this.etablissements.push(`${etablissement.id}`);
          });
          if (this.etablissements.length === 1) {
            this.etablissementsSelected = this.etablissements;
          }
        });
      }
    },
    classesArray(ids) {
      this.loading = true;
      this.classesArrayLoading = false;
      this.classes = [];
      ids.forEach((id) => {
        AdminService.getClasseByID(id).then(({ data }) => {
          data.classes.forEach((classe) => {
            this.classes.push(classe.name);
          });
          this.devis = data;
          this.classesArrayLoading = true;
          this.loading = false;
        });
      });
    },
    actifText(item) {
      if (item === true) {
        return "mdi-check-circle-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    },
    getColor(Status) {
      if (Status === true) return "green";
      else return "secondary";
    },

    getUtilisateurEtablissement() {
      this.refreshPage();
    },

    setPage(page) {
      this.pageInfo.page = page;
      this.refreshPage();
    },

    changeSort(sort) {
      this.pageInfo.sortBy = [sort];
      this.refreshPage();
    },

    setPageSize(size) {
      this.pageInfo.itemsPerPage = size;
      this.changeSort([]);
    },

    changeSortDesc(sort) {
      this.pageInfo.sortDesc = [sort];
      this.setPage(1);
    },

    fetchUsers({ sortBy, sortDesc, page, itemsPerPage, totalItems }) {
      return AdminService.searchUsers(
        page,
        itemsPerPage >= 0 ? itemsPerPage : totalItems,
        this.classeSelected,
        this.etablissementsSelected,
        this.status === null ? null : this.status !== "Inactif",
        this.role !== null ? [this.role] : ["ELEVE", "PROF"],
        null,
        sortBy,
        sortDesc
      );
    },

    selectAll() {
      if (this.selectedUsers.length === this.pageInfo.totalItems) {
        this.selectedUsers = [];
      } else {
        this.loading = true
        this.fetchUsers({
          sortBy: [],
          sortDesc: [],
          page: 1,
          itemsPerPage: this.pageInfo.totalItems,
          totalItems: this.pageInfo.totalItems,
        }).then(({ data }) => {
          this.selectedUsers = data.content;
        }).finally(() => {
          this.loading = false
        });
      }
    },

    refreshPage() {
      this.loading = true;
      this.fetchUsers(this.pageInfo)
        .then(({ data }) => {
          this.utilisateurs = [];
          this.pageInfo.totalItems = data.totalElements;
          this.utilisateurs = data.content;
        })
        .catch((error) => {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goResumer() {
      this.currentStep++;
    },

    async exporter() {
      this.loading = true;
      let identifiants = this.selectedUsers.map(
        (utilisateur) => utilisateur.identifiant
      );

      let data = {
        identifiants: identifiants,
      };

      return await AdminService.getAllUsersDataBiblio(data)
        .then((response) => {
          Utils.downloadFile(response.data, "users.csv");

          return this.allUsers;
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Exportation terminée",
          });
          this.$router.push({ name: "DashboardAdmin" });
        });
    },

    async exporterQrCode() {
      this.loading = true;
      let identifiants = this.selectedUsers.map(
        (utilisateur) => utilisateur.identifiant
      );

      let data = {
        identifiants: identifiants,
      };

      AdminService.exportQrCodeUtilisateurs(data)
        .then((res) => {
          const data =
            "data:application/octet-stream;charset=utf-16le;base64," + res.data;
          const link = document.createElement("a");
          link.setAttribute(
            "download",
            `export_utilisateurs_${this.etablissementsSelected}.pdf`
          );
          link.setAttribute("href", data);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Le fichier a été téléchargé",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.clicked = false;
          this.$router.push({ name: "DashboardAdmin" });
        });
    },
    async onEtablissementsSelected() {
      this.classe = null;
      this.classesArray(this.etablissementsSelected);
    },
  },
};
</script>

<style scoped></style>
