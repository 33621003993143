/**
 * Download a file by creating virtual link and force clic on it
 * @param { Blob } content file content
 * @param { string } filename filename
 */
function downloadFile(content, filename) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(content);
    a.download = filename;
    a.click();
}

const Utils = {
    downloadFile
}

export default Utils