<template>
  <v-card
      height="100%"
      color="backgroundColor"
  >
    <ExportTab/>
  </v-card>
</template>

<script>
import ExportTab from "@/components/ExportComponent/ExportTab.vue";

export default {
  components: {
    ExportTab
  },
};
</script>
